import * as React from "react"
import Seo from "../components/seo"

const NotFoundPage = () => {
    return (
      <>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      </>
    )
}

export default NotFoundPage
